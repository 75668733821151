import request from '@/utils/request';
import { stringify } from 'qs';

// icbc
// 可用活动列表
export function getActivityList(id) {
  return request({
    url: `/icbc/h5/discountActivityList?organizationId=${id}`,
    method: 'get'
  });
}

// 校验活动是否可参与
export function getVerify(activityId, userId) {
  return request({
    url: `/icbc/h5/isActivityApply?activityId=${activityId}&userId=${userId}`,
    method: 'get'
  });
}

// 充值
export function getpayInActivity(activityId, userId, amount) {
  return request({
    url: `/icbc/h5/payInActivity?userId=${userId}&amount=${amount}&activityId=${activityId}`,
    method: 'get'
  });
}

// 设置支付状态为成功
export function getQueryOKy(params) {
  return request({
    url: `/icbc/h5/queryOK?id=${params.id}`,
    method: 'post'
  });
}

// 查询可用活动列表
export function getSearch() {
  return request({
    url: `/icbc/h5/discountActivityList`,
    method: 'get'
  });
}

// Org
// 获取活动列表
export function getListOrg(params) {
  return request({
    url: `/organization/discountActivity/pagination?${stringify(params)}`,
    method: 'get'
  });
}

// 获取活动信息
export function getDataOrg(id) {
  return request({
    url: `/organization/discountActivity/${id}`,
    method: 'get'
  });
}

// 添加新优惠活动
export function getAddActivityOrg(data) {
  return request({
    url: `/organization/discountActivity`,
    method: 'post',
    data
  });
}

// 修改活动状态
export function getChangeStatusOrg(id, data) {
  return request({
    url: `/organization/discountActivity/${id}/status`,
    method: 'post',
    data
  });
}

// 编辑活动信息
export function editDiscountActivityOrg(id, data) {
  return request({
    url: `/organization/discountActivity/${id}`,
    method: 'post',
    data
  });
}

// 活动优惠记录列表
export function getRecordListOrg(params) {
  return request({
    url: `/organization/discountRecord/pagination?${stringify(params)}`,
    method: 'get'
  });
}

// 活动优惠记录累计数据（总计）
export function getRecordSumOrg(activityId) {
  return request({
    url: `/organization/discountRecord/sum?activityId=${activityId}`,
    method: 'get'
  });
}

// 查询可用活动列表
export function getSearchOrg() {
  return request({
    url: `/organization/discountActivityList`,
    method: 'get'
  });
}
